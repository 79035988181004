@use '@angular/material' as mat;
// theme.scss

$my-app: (
  10:  #e8fafb2e,
  50:  #F1F4FF, // used
  100: #D9E0FF, // used 
  200: #FFA34E, // warn
  300: #E0335D, //used
  400: #9AACFF, // used
  500: #0F2CB2, // used
  600: #0F2CB2, // primary
  700: #21294E, // used
  800: #21294F, // navy
  900: #7B92FF, // accent
  A100: #ffffff,
  A200: #AEAEAE, // used
  A400: #a1f3fb,
  A700: #000000, // used
  contrast: (
    50 : #32c5d25c,
    100 : #32c5d2c2,
    200 : white,
    300 : #0F2CB2,
    400 : #0F2CB2,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #0F2CB2,
    A200 : #ffffff,
    A400 : #0F2CB2,
    A700 : #ffffff,
  ));

$my-app-primary: mat.define-palette($my-app, 600);
$my-app-accent : mat.define-palette($my-app, 900);
$my-app-warn : mat.define-palette($my-app, 200);
$my-app-error : mat.define-palette($my-app, 300);
$my-app-gray : mat.define-palette($my-app, 'A200');

$my-app-background : mat.define-palette($my-app, 50);
$my-app-background-medium : mat.define-palette($my-app, 100);
$my-app-background-dark : mat.define-palette($my-app, 700);
$my-app-sidenav : mat.define-palette($my-app, 50);

$my-fab-icon : mat.define-palette($my-app, 800);
$my-fab-background : mat.define-palette($my-app, 900);
$my-fab-hover : mat.define-palette($my-app, 400);

$my-text-color : mat.define-palette($my-app, 800);
$my-link-color : mat.define-palette($my-app, 800);
$my-link-hover-color : mat.define-palette($my-app, 500);
$my-title-color : mat.define-palette($my-app, 600);
$my-eval-border-color: mat.define-palette($my-app, 900);
$my-eval-background-color:  mat.define-palette($my-app, 50);


$my-theme: mat.define-light-theme(
  $my-app-primary,
  $my-app-accent,
  $my-app-warn
);

.mat-cell {
  color:dimgray !important;
}
.main-col {
  color: black !important;
  font-weight: bolder;
}

mat-icon {
  font-family: 'Material Icons Outlined';
}