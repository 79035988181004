@use "@angular/material" as mat;
@import "./theme.scss";

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

@import "https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined";

@include mat.core();
@include mat.all-component-themes($my-theme);

html,
body {
  height: 100%;
  margin: 0;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  line-height: 158.47%;
  color: mat.get-color-from-palette($my-text-color);
}
* {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 158.47%;
}

h1 {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 158%;
  color: mat.get-color-from-palette($my-app-primary);
}
h2 {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 158%;
  color: mat.get-color-from-palette($my-app-primary);
}
h3 {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: mat.get-color-from-palette($my-text-color);
}

.textbold {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 700;
}

.smalltext {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}


.smalltexbold {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: mat.get-color-from-palette($my-text-color);
}

a {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: mat.get-color-from-palette($my-link-color);
  
  &:hover {
    color: mat.get-color-from-palette($my-link-hover-color);
  }
}

.hidden {
  display: none;
}

.hand {
  cursor: pointer;
}

.mat-radio-label {
  margin-right: 10px !important;
}

.pull-right {
  float: right;
}

// Tables
.std-table-container {
  position: relative;
  min-height: 200px;
  flex-direction: column;
}

.std-table-container > div {
  /* these are the flex items */
  flex: 1;
}

.std-table-table-container {
  position: relative;
  //max-height: 540px;
  overflow: auto;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
}

.std-table-loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.std-table-rate-limit-reached {
  color: #980000;
  max-width: 360px;
  text-align: center;
}

/* Column Widths */
.mat-column-number,
.mat-column-state {
  max-width: 64px;
}

.mat-column-created {
  max-width: 124px;
}

td.mat-cell.action-col {
  width: 40px;
  padding-right: 0px;
  text-align: center;
}

mat-spinner::ng-deep {
  &.bt-spinner {
    display: inline;
    padding-right: 30px;
  }
}

.hand {
  cursor: pointer;
}

.red-text {
  color: #ed6b75;
}

.mat-error {
  line-height: initial;
  font-size: 11px;
  & strong{
    font-size: 11px;
  }
}

// hide arrows from input number
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button,
input[type="number"] {
  -webkit-appearance: none !important;
  -moz-appearance: textfield !important;
  margin: 0;
}

.mat-expansion-panel {
  margin-bottom: 1px !important;
  margin-top: 0px !important;
}

.mat-tooltip-info {
  background: #1f2b3de3;
  color: white !important;
  margin-right: 5px !important;
  font-size: 12px;
}

.mat-cell.logo {
  span {
    img {
      width: 100px;
      border: 1px solid lightgray;
      display: block;
    }
  }
}

.mat-mini-fab .mat-button-wrapper {
  line-height: 0px !important;
}

.tab-error-icon {
  color: #ed6b75;
  margin-top: -10px;
}

tr.mat-header-row {
  background: white !important;
}

tr.mat-row:nth-child(2n + 1):not(.expand-row) {
  background: #f6f6f6;
}

tr.example-element-row.expand-row:nth-child(4n + 1) {
  background: #f6f6f6;
}

tr.mat-row:hover:not(.mat-header-row) {
  background: #f1f4ff !important;
}

.actions-col {
  & button {
    background: transparent;
  }
}

.mat-chip.mat-standard-chip {
  background-color: #f1f1f1;
  color: rgba(0, 0, 0, 0.87);
}

.content-wrapper {
  padding: 20px;
}

.badge {
  font-size: 11px !important;
  font-weight: 700;
  height: 18px;
  line-height: 17px;
  color: #fff;
  padding: 3px 6px;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  border-radius: 5px !important;
  text-shadow: none !important;
  text-align: center;
  width: 80px;
  white-space: nowrap;

  &.client {
    background-color: mat.get-color-from-palette($my-app-primary);
  }
  &.partner {
    background-color: mat.get-color-from-palette($my-app-accent);
  }
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  .wrapper-title {
    background-color: white;
    min-height: 168px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .wrapper-title-content {
      max-width: calc(1160px + 40px);
      padding:0 20px;
      margin: auto;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h1 {
        color: mat.get-color-from-palette($my-title-color);
      }
    }
  }
  .wrapper-content {
    max-width: 1160px;
    padding:0 20px;
    // width: 100%;
    margin: auto;
    justify-content: space-between;
    height: inherit;
  }

  .footer {
    color: mat.get-color-from-palette($my-app-accent);
    font-size: 12px;
    text-align: center;
    padding-bottom: 20px;
  }
}

.mat-card:not([class*="mat-elevation-z"]) {
  box-shadow: none;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.05));
}

.card-header-row {
  height: 60px;
}

mat-card {
  color: mat.get-color-from-palette($my-text-color) !important;
}
mat-icon {
  color: mat.get-color-from-palette($my-text-color);
}

dropdownmenu {
  button.mat-mini-fab {
    mat-icon {
      color: white;
    }
  }
}
.mat-flat-button {
  height:40px;
  font-size: 14px;
}

.info-box {
  border:1px solid mat.get-color-from-palette($my-app-background-medium);
  padding:20px;
  border-radius: 5px;
  margin-bottom:20px;
  color: mat.get-color-from-palette($my-text-color);
}
.bt-spinner {
  display: inline;
  padding-right: 30px;
}

// mat-divider
.mat-divider {
  border-top-color: rgba(241, 244, 255, 1) !important;
  border-width: 1px;
}

.mat-divider-vertical {
  border-right-color: rgba(241, 244, 255, 1) !important;
  border-width: 1px;
}
strong {
  font-weight: 700;
}
em {
  font-style: italic;
}
.ql-indent-1 {
  padding-left: 3em;
}
.ql-indent-2 {
  padding-left: 6em;
}
.ql-indent-3 {
  padding-left: 9em;
}
.ql-indent-4 {
  padding-left: 12em;
}
.ql-indent-5 {
  padding-left: 15em;
}
.ql-indent-6 {
  padding-left: 18em;
}
.ql-indent-7 {
  padding-left: 21em;
}
.ql-indent-8 {
  padding-left: 24em;
}
.ql-align-center {
  text-align: center;
}
.ql-align-right {
  text-align: right;
}
.ql-align-justify {
  text-align: justify;
}
.ql-size-huge {
  font-size: 2.5em;
}
.ql-size-large {
  font-size: 1.5em;
}
.ql-size-small {
  font-size: 0.75em;
}

.print-btn {
  position: absolute!important;
  top: 0;
  right: 35px;
  mat-icon {
   color: mat.get-color-from-palette($my-app-primary)
  }
}